import React from 'react';
import { PageProps } from 'gatsby';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';

import Layout, { LayoutOptions } from '../../components/Layout/Layout';
import { LocaleNameSpaceKeys } from '../../localization/translations';
import CommonData from '../../data/common/common';
import ExternalLink from '../../components/ExternalLink/ExternalLink';
import { useLocaleContext } from '../../context/Locale';
import { generateList } from '../../shared/utils/accordion';
import './style.scss';

type Props = PageProps & WithTranslation;

const Accessibility: React.FC<Props> = (props: Props) => {
  const { t } = props;
  const { activeLocaleTag } = useLocaleContext();

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('seo.title'),
    },
    page: {
      title: t('seo.title'),
    },
  };

  return (
    <Layout options={layoutOptions} className="Accessibility">
      <div className="Accessibility__generic-gap">
        <h2 className="h3">{t('heading')}</h2>
        <p>
          <Trans t={t} i18nKey="bodyParagraph">
            <ExternalLink href={CommonData.websites.justice.rightsNFreedom[activeLocaleTag]} />
          </Trans>
        </p>
      </div>
      <div className="Accessibility__generic-gap">
        <h2 className="h3">{t('messageFromCEO.heading')}</h2>
        <p>
          <Trans t={t} i18nKey="messageFromCEO.body" />
        </p>
      </div>
      <div className="Accessibility__generic-gap">
        <h2 className="h3">{t('consultations.heading')}</h2>
        <p>
          <Trans t={t} i18nKey="consultations.body" />
        </p>
      </div>
      <div className="Accessibility__generic-gap">
        <h2 className="h3">{t('general.heading')}</h2>
        {generateList(t('general.content', { returnObjects: true }), activeLocaleTag)}
      </div>
      <div className="Accessibility__generic-gap">
        <h2 className="h3">{t('sectionFive.heading')}</h2>
        {generateList(t('sectionFive.content', { returnObjects: true }), activeLocaleTag)}
      </div>
    </Layout>
  );
};

export default withTranslation(LocaleNameSpaceKeys.ACCESSIBILITY)(Accessibility);
