import React, { Fragment } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Accordion, AccordionItem } from '../../components/Accordion/Accordion';
import Link from '../../components/Link/Link';
import ExternalLink from '../../components/ExternalLink/ExternalLink';
import { getPathFromRouteKey } from './getPathFromRouteKey';
import RouteKeys from '../../../config/RouteKeys';
import CommonData from '../../data/common/common';
import { LocaleTag } from '../../../config/locales';
import { LocaleNameSpaceKeys } from '../../localization/translations';

export const generateContent = (item: any, activeLocaleTag: LocaleTag = LocaleTag.EN) => {
  const { t } = useTranslation();

  return item.bodies.map((body: any, index: number) => (
    <Fragment key={`content-${index}`}>
      <Trans
        {...(item.bodyLinks?.[index]?.length
          ? {
              components: item.bodyLinks[index].map(
                (bLink: { path: string; type: string; subType?: string }) => {
                  const path =
                    bLink.type === 'internal'
                      ? getPathFromRouteKey(
                          bLink.path
                            .split('.')
                            .reduce((accum: any, next: any) => accum[next], RouteKeys)
                        )
                      : bLink.path
                          .split('.')
                          .reduce(
                            (accum: any, next: any) => accum[next],
                            CommonData.websites as any
                          )[activeLocaleTag];

                  const getLinkPrefix = (subType?: string) => {
                    if (subType) {
                      return bLink.subType === 'email' ? 'mailto:' : 'tel:';
                    }

                    return '';
                  };

                  return bLink.type === 'internal' ? (
                    <Link
                      to={getPathFromRouteKey(
                        bLink.path
                          .split('.')
                          .reduce((accum: any, next: any) => accum[next], RouteKeys)
                      )}
                    />
                  ) : (
                    <ExternalLink
                      href={`${getLinkPrefix(bLink.subType)}${path}`}
                      {...(bLink.subType
                        ? {
                            ariaLabel: `${t(
                              `${LocaleNameSpaceKeys.COMMON}:${bLink.subType}`
                            )} ${path}`,
                          }
                        : {})}
                    />
                  );
                }
              ),
            }
          : {})}
      >
        {body}
      </Trans>
      {item.lists?.[index]?.length ? (
        <ul>
          {item.lists[index].map(
            (listItem: { text: string; links?: { path: string; type: string }[] }, i: number) => (
              <li key={`item-${i}`}>
                <Trans
                  {...(listItem.links
                    ? {
                        components: listItem.links.map((link) =>
                          link.type === 'internal' ? (
                            <Link
                              to={getPathFromRouteKey(
                                link.path
                                  .split('.')
                                  .reduce((accum: any, next: any) => accum[next], RouteKeys)
                              )}
                            />
                          ) : (
                            <ExternalLink
                              href={(CommonData.websites as any)[link.path][activeLocaleTag]}
                            />
                          )
                        ),
                      }
                    : {})}
                >
                  {listItem.text}
                </Trans>
              </li>
            )
          )}
        </ul>
      ) : null}
    </Fragment>
  ));
};

export const generateList = (list: any[], activeLocaleTag: LocaleTag = LocaleTag.EN) => {
  return (
    <Accordion allowZeroExpanded allowMultipleExpanded>
      {list.map((item, i) => {
        return (
          <AccordionItem key={`${item.title}-${i}`} buttonOptions={{ label: item.title }}>
            {generateContent(item, activeLocaleTag)}
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};
